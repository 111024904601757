var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticClass: "w-200 mb-5",
            attrs: { allowClear: "", placeholder: "名称搜索" },
            model: {
              value: _vm.params.title,
              callback: function($$v) {
                _vm.$set(_vm.params, "title", $$v)
              },
              expression: "params.title"
            }
          }),
          _c(
            "a-select",
            {
              staticClass: "ml-5 w-200 mb-5",
              attrs: {
                allowClear: "",
                showSearch: "",
                "filter-option": _vm.untils.filterOption,
                placeholder: "一级大分类搜索"
              },
              on: {
                change: function($event) {
                  return _vm.handleChangeTopCategory($event)
                }
              },
              model: {
                value: _vm.params.categoryId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "categoryId", $$v)
                },
                expression: "params.categoryId"
              }
            },
            _vm._l(_vm.topCategoryTypeList, function(item) {
              return _c(
                "a-select-option",
                { key: item.id, attrs: { value: item.id } },
                [_vm._v(_vm._s(item.categoryName))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticClass: "ml-5 w-200 mb-5",
              attrs: {
                allowClear: "",
                showSearch: "",
                "filter-option": _vm.untils.filterOption,
                placeholder: "二级分类搜索"
              },
              model: {
                value: _vm.params.topicId,
                callback: function($$v) {
                  _vm.$set(_vm.params, "topicId", $$v)
                },
                expression: "params.topicId"
              }
            },
            _vm._l(_vm.leftCategoryTypeList, function(item) {
              return _c(
                "a-select-option",
                { key: item.id, attrs: { value: item.id } },
                [_vm._v(_vm._s(item.topicName))]
              )
            }),
            1
          ),
          _c(
            "a-select",
            {
              staticClass: "ml-5 w-200 mb-5",
              attrs: {
                allowClear: "",
                showSearch: "",
                placeholder: "是否可用"
              },
              model: {
                value: _vm.params.status,
                callback: function($$v) {
                  _vm.$set(_vm.params, "status", $$v)
                },
                expression: "params.status"
              }
            },
            _vm._l(_vm.isCanUseStatusList, function(item) {
              return _c(
                "a-select-option",
                { key: item.value, attrs: { value: item.value } },
                [_vm._v(_vm._s(item.name))]
              )
            }),
            1
          ),
          _c(
            "a-button",
            {
              staticClass: "mb-5",
              attrs: { type: "primary" },
              on: { click: _vm.search }
            },
            [_vm._v("搜索")]
          ),
          _c(
            "a-button",
            {
              staticClass: "mb-5",
              attrs: { icon: "plus" },
              on: { click: _vm.handleAdd }
            },
            [_vm._v("添加数据")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TableList", {
            attrs: {
              list: _vm.data,
              pagination: _vm.pagination,
              topCategoryTypeList: _vm.topCategoryTypeList
            },
            on: { changePage: _vm.handleChangePage, success: _vm.getList }
          })
        ],
        1
      ),
      _c("EditorCategoryDetailPopup", {
        ref: "editorCategoryDetailPopupEl",
        attrs: { topCategoryTypeList: _vm.topCategoryTypeList },
        on: { success: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }