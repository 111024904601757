var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "content" },
    [
      _c(
        "div",
        { staticClass: "top" },
        [
          _c("a-input", {
            staticClass: "w-200",
            attrs: { allowClear: "", placeholder: "用户ID" },
            model: {
              value: _vm.params.userId,
              callback: function($$v) {
                _vm.$set(_vm.params, "userId", $$v)
              },
              expression: "params.userId"
            }
          }),
          _c(
            "a-button",
            { attrs: { type: "primary" }, on: { click: _vm.search } },
            [_vm._v("搜索")]
          ),
          _c(
            "a-button",
            {
              staticClass: "ml-10",
              attrs: { type: "primary", icon: "plus" },
              on: { click: _vm.handleAddPushMessage }
            },
            [_vm._v("新增推送")]
          )
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "table-content" },
        [
          _c("TableList", {
            attrs: { list: _vm.data, pagination: _vm.pagination },
            on: { changePage: _vm.handleChangePage }
          })
        ],
        1
      ),
      _c("AddPushMessagePopup", {
        ref: "addPushMessagePopupEl",
        on: { success: _vm.getList }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }