var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-modal",
    {
      attrs: { width: 800, title: "推送信息" },
      on: { cancel: _vm.handleCancel, ok: _vm.handleConfirmEditorTemplate },
      model: {
        value: _vm.showPopup,
        callback: function($$v) {
          _vm.showPopup = $$v
        },
        expression: "showPopup"
      }
    },
    [
      _c(
        "a-form-model",
        _vm._b({}, "a-form-model", _vm.layout, false),
        [
          _c(
            "a-form-item",
            { attrs: { label: "模版选择" } },
            [
              _c(
                "a-select",
                {
                  staticClass: "w-500",
                  attrs: {
                    "filter-option": _vm.untils.filterOption,
                    allowClear: "",
                    showSearch: "",
                    placeholder: "请选择信息模版"
                  },
                  on: {
                    search: _vm.handleSearchTemplate,
                    change: _vm.handleChangeSelectedTemplate
                  },
                  model: {
                    value: _vm.params.pushTemplateId,
                    callback: function($$v) {
                      _vm.$set(_vm.params, "pushTemplateId", $$v)
                    },
                    expression: "params.pushTemplateId"
                  }
                },
                _vm._l(_vm.messageTemplateList, function(items) {
                  return _c(
                    "a-select-option",
                    { key: items.id, attrs: { value: items.id } },
                    [_vm._v(_vm._s(items.title + "---【" + items.id + "】"))]
                  )
                }),
                1
              ),
              _vm.params.pushTemplateId
                ? _c(
                    "a-button",
                    {
                      staticClass: "ml-10",
                      attrs: { type: "link" },
                      on: {
                        click: function($event) {
                          return _vm.handleGoToTemplateList(
                            _vm.params.pushTemplateId
                          )
                        }
                      }
                    },
                    [_vm._v("查看模版细节>")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm.pushObject === 4
            ? _c(
                "a-form-item",
                { attrs: { label: "用户选择" } },
                [
                  _c(
                    "a-select",
                    {
                      attrs: {
                        allowClear: "",
                        mode: "multiple",
                        dropdownMatchSelectWidth: false,
                        "filter-option": _vm.untils.filterOption,
                        showSearch: "",
                        placeholder: "请输入店铺名称/昵称/userId"
                      },
                      on: { search: _vm.handleSearchUser },
                      model: {
                        value: _vm.params.userIds,
                        callback: function($$v) {
                          _vm.$set(_vm.params, "userIds", $$v)
                        },
                        expression: "params.userIds"
                      }
                    },
                    _vm._l(_vm.userInfoList, function(item) {
                      return _c(
                        "a-select-option",
                        { key: item.userId, attrs: { value: item.userId } },
                        [
                          _vm._v(
                            _vm._s(item.userName + "【" + item.userId + "】") +
                              " "
                          )
                        ]
                      )
                    }),
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }