<template>
  <div class="content">
    <div class="top">
      <a-input
          allowClear
          v-model="params.userId"
          class="w-200"
          placeholder='用户ID'
      ></a-input>
      <a-button
          type="primary"
          @click="search"
      >搜索</a-button>
      <a-button
          class="ml-10"
          type="primary"
          @click="handleAddPushMessage"
          icon="plus"
      >新增推送</a-button>
    </div>
    <div class="table-content">
      <TableList
          :list="data"
          :pagination="pagination"
          @changePage="handleChangePage"
      />
    </div>
<!--  推送  -->
    <AddPushMessagePopup ref="addPushMessagePopupEl" @success="getList"/>
  </div>
</template>
<script>
import TableList from "@/views/cmsPage/shopManage/messagePushList/_components/TableList/index.vue"
import {
  dqArriveTypeList, dqCheckPayTypeList, dqCheckPayTypeListByAD,
  dqCheckResultAfterByBuyerTypeList,
  dqCheckResultTypeList, dqCheckStatusTypeList
} from "@/views/cmsPage/checkManage/_data"
import {getMessagePushListApi} from "@/views/cmsPage/shopManage/messagePushList/_apis"
import AddPushMessagePopup from "@/views/cmsPage/shopManage/messagePushList/_components/AddPushMessagePopup/index.vue"

export default {
  components: {
    TableList, AddPushMessagePopup
  },
  data() {
    return {
      data: [],
      dqCheckPayTypeList,
      dqCheckPayTypeListByAD,
      dqCheckResultTypeList,
      dqCheckStatusTypeList,
      dqCheckResultAfterByBuyerTypeList,
      arriveTypeList: dqArriveTypeList,
      tempData: [],
      pagination: {
        total: 0,
        current: 1,
        pageSize: 10,
        showTotal: (total) => `共${total}条`,
      },
      params: {
        pageNum: 1,
        pageSize: 10,
        userId: undefined
      },
    };
  },
  async mounted() {
    await this.getList()
  },
  methods: {
    /** 新增推送 */
    handleAddPushMessage() {
      this.$refs.addPushMessagePopupEl.show()
    },
    /** 翻页 */
    handleChangePage(page) {
      const { current } = page;
      this.$set(this.pagination, "current", current);
      this.$set(this.params, "pageNum", current);
      this.getList();
    },
    /** 搜索 */
    search() {
      this.$set(this.pagination, "current", 1);
      this.$set(this.params, "pageNum", 1);
      this.getList();
    },
    /**  获取列表数据 */
    async getList() {
      this.$loading.show()
      const res = await getMessagePushListApi(this.params)
      this.$loading.hide()
      if (res.status !== '200') return;
      this.data = res.data.records;
      this.pagination.total = res.data.total;
    },
  },
};
</script>
<style lang="scss" scoped>
.select-all-text {
  margin-left: 20px;
  margin-right: 20px;
}
.content {
  display: flex;
  flex-direction: column;
  .top-search {
    display: flex;
    align-items: center;
  }
  .table-content {
    flex: 1;
    overflow-y: auto;
  }
}

.top {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 20px;
  input {
    width: 250px;
    margin-right: 25px;
    margin-bottom: 20px;
  }
  button {
    margin-left: 10px;
  }
}

.pagination {
  text-align: right;
  margin-top: 10px;
}
</style>
